import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import Vue from "vue";
import firebase from "firebase/app";
// import { ApiGetDataException } from "@/plugins/exceptions";

const API_URL = "/api/v2";

const apiMixins = {
  computed: {
    ...mapState("user", {
      firebaseToken: "token"
    }),
    ...mapState("filters", {
      filterUpdated: "updated",
    }),
    ...mapGetters("filters", {
      filteredQueryString: "filteredQueryString"
    }),
    apiIsDevServer: function () {
      if (Vue.config.devtools) {
        return true;
      } else {
        return false;
      }
    },
    apiServer: function () {
      const API_HOST = this.apiIsDevServer ? "http://localhost:8000" : "https://visex.mokifood.com";
      this.metaSetServer(API_HOST);
      return API_HOST;
    },
    apiAxiosConfig: async function () {
      // console.info("AXIOS using token " + this.firebaseToken);
      return {
        headers: {authorization: this.firebaseToken}
      };
    }
  },
  /*data: function () {
    return {
      axios_configg: {
        headers: {Authorization: `Bearer ${this.firebaseToken}`}
      },
      apiUrl: ""
    };
  },*/
  watch: {
    // filterUpdated: function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.apiGetData();
    //   }
    // },
    /*apiUrl: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.apiGetData();
      }
    }*/
  },
  methods: {
    ...mapActions("meta", {"metaSetServer": "setServer"}),
    apiGetData: async function (url, filter = {}) {
      const BASE_URL = "/api/v2";
      let fetchUrl = this.apiServer + BASE_URL + url;
      if (Object.keys(filter).length === 0) {
        fetchUrl = fetchUrl + "?" + this.filteredQueryString;
      } else {
        fetchUrl = fetchUrl + "?" + new URLSearchParams(filter);
      }
      let result = [];
      console.log("apiGetData()");
      console.log(fetchUrl);
      try {
        // let authHeader = await this.apiAxiosConfig
        let authHeader = {
              headers: {
                authorization: await firebase.auth().currentUser.getIdToken(false)
              }
          }
        // let authHeader = {
        //   authorization: this.firebaseToken
        // }
        console.log(authHeader);
        result = await axios.get(fetchUrl, authHeader);
        console.log(result);
      } catch (err) {
        console.log("Type of URL");
        console.log(typeof url);
        console.log(`Error getting data from ${url}`);
        console.error(err);
        let parent = this.$options.name;
        console.log("Called from " + parent);
        throw err;
      }
      return result.data;
    },
    apiGetUserRoles: async function () {
      let url = API_URL + "/meta/roles";
      return url;
    }
  }
};

export { apiMixins };
